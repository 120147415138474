import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'

const Quote = ({ data = {} }) => {
  const { quote } = data

  return (
    <section className="quote">
      <div className="quote__wrapper">
        <BlockContent
          className="rc"
          renderContainerOnSingleChild
          blocks={quote}
          serializers={serializers}
        />
      </div>
    </section>
  )
}

export default Quote
